<template>
  <v-dialog v-model="show" :max-width="options.maxWidth" 
  :transition="transition"
  :fullscreen="$vuetify.breakpoint.xsOnly" scrollable>
  <v-card v-if="this.options.userToEdit">
        <v-toolbar flat color="toolbars">
            <v-toolbar-title>Update Permissions</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="close">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
            <v-card-text>
              <v-layout column>
            <span class="pb-3 pt-3 mb-3" style="font-weight:700; font-size:18px;">{{this.options.userToEdit.displayName}}</span>

                    <v-select
                      :items="items"
                      outlined
                      label="Permission Level"
                      v-model="selectedPermissionLevel"
                    ></v-select>
<v-list three-line v-if="selectedPermissionLevel=='Chart Administrator'">
          <v-list-item
            v-for="(item, i) in permissionListAdmin"
            :key="i"
            dense
          >
          <v-list-item-avatar>
                <v-icon>{{item.icon}}</v-icon>
              </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{item.title}}</v-list-item-title>
              <v-list-item-subtitle>{{item.text}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
</v-list>
<v-list dense three-line v-if="selectedPermissionLevel=='Chart Sharer'">
          <v-list-item
          dense
            v-for="(item, i) in permissionListSharer"
            :key="i"
          >
          <v-list-item-avatar>
                <v-icon>{{item.icon}}</v-icon>
              </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{item.title}}</v-list-item-title>
              <v-list-item-subtitle>{{item.text}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
</v-list>
<v-list dense three-line v-if="selectedPermissionLevel=='Chart Viewer'">
          <v-list-item
          dense
            v-for="(item, i) in permissionListViewer"
            :key="i"
          >
          <v-list-item-avatar>
                <v-icon>{{item.icon}}</v-icon>
              </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{item.title}}</v-list-item-title>
              <v-list-item-subtitle>{{item.text}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
</v-list>

<v-list dense three-line v-if="selectedPermissionLevel=='Remove All Permissions'">
          <v-list-item
          dense
            v-for="(item, i) in permissionListRemoved"
            :key="i"
          >
          <v-list-item-avatar>
                <v-icon>{{item.icon}}</v-icon>
              </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{item.title}}</v-list-item-title>
              <v-list-item-subtitle>{{item.text}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
</v-list>
              </v-layout>

            </v-card-text>

<v-card-actions>
  <v-btn depressed  @click="close">Cancel</v-btn>
  <v-spacer></v-spacer>
          <v-btn v-if="this.selectedPermissionLevel === 'Remove All Permissions'" depressed color="red" dark @click="updatePermissions()">Remove All Permissions</v-btn>
          <v-btn v-else depressed color="primary" dark @click="updatePermissions()">Update Permissions</v-btn>
</v-card-actions>
      </v-card>

    </v-dialog>
</template>

<script>
import DialogMixin from '@/core/mixins/DialogMixin'
import { mapState, mapActions } from 'vuex'
import bus from '@/core/helpers/bus'

export default {
  mixins: [DialogMixin],
  components: {},
  data() {
    return {
      selectedPermissionLevel: '',
      faxNumbers: [],
      emailAddresses: [],
      permissionListAdmin: [ {title: 'View Chart', text: 'View the entire chart as well as view information added to the chart in the future', icon: 'mdi-check'},
        {title: 'Edit Patient Info', text: 'Modify patient information in the chart.', icon: 'mdi-check'},
        {title: 'Share Chart', text: 'Share the chart with other individuals and organizations.', icon: 'mdi-check'},
        {title: 'Edit Chart Permissions', text: 'Modify other user\'s permissions on the chart', icon: 'mdi-check'},
        {title: 'Create Records Requests', text: 'Request records from providers to be added to the chart.', icon: 'mdi-check'} ],
      permissionListSharer: [ {title: 'View Chart', text: 'View the entire chart as well as view information added to the chart in the future', icon: 'mdi-check'},
        {title: 'Edit Patient Info', text: 'Modify patient information in the chart.', icon: 'mdi-close'},
        {title: 'Share Chart', text: 'Share the chart with other individuals and organizations.', icon: 'mdi-check'},
        {title: 'Edit Chart Permissions', text: 'Modify other user\'s permissions on the chart', icon: 'mdi-check'},
        {title: 'Create Records Requests', text: 'Request records from providers to be added to the chart.', icon: 'mdi-close'} ],
      permissionListViewer: [ {title: 'View Chart', text: 'View the entire chart as well as view information added to the chart in the future', icon: 'mdi-check'},
        {title: 'Edit Patient Info', text: 'Modify patient information in the chart.', icon: 'mdi-close'},
        {title: 'Share Chart', text: 'Share the chart with other individuals and organizations.', icon: 'mdi-close'},
        {title: 'Edit Chart Permissions', text: 'Modify other user\'s permissions on the chart', icon: 'mdi-close'},
        {title: 'Create Records Requests', text: 'Request records from providers to be added to the chart.', icon: 'mdi-close'} ],
      permissionListRemoved: [ {title: 'View Chart', text: 'View the entire chart as well as view information added to the chart in the future', icon: 'mdi-close'},
        {title: 'Edit Patient Info', text: 'Modify patient information in the chart.', icon: 'mdi-close'},
        {title: 'Share Chart', text: 'Share the chart with other individuals and organizations.', icon: 'mdi-close'},
        {title: 'Edit Chart Permissions', text: 'Modify other user\'s permissions on the chart', icon: 'mdi-close'},
        {title: 'Create Records Requests', text: 'Request records from providers to be added to the chart.', icon: 'mdi-close'} ],
      items: ['Chart Administrator', 'Chart Sharer', 'Chart Viewer', 'Remove All Permissions']
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('patient', ['patient']),
    transition() {
      return this.$vuetify.breakpoint.xsOnly
        ? 'dialog-bottom-transition'
        : 'scale-transition'
    }
  },
  mounted() {
    this.$on('open', this.onOpen)
  },
  methods: {
    ...mapActions('provider', ['shareChart']),
    ...mapActions('patient', [
      'getPatient',
      'updateIndividualPermissions',
      'getPermissions',
      'getPreliminaryPermissions'
    ]),
    ...mapActions('auth', ['userActivity']),
    toast(type, text) {
      bus.$emit('toast', { type, text })
    },
    updatePermissions() {
      let permissionsToBeAssigned = []
      if (this.selectedPermissionLevel === 'Chart Administrator') {
        permissionsToBeAssigned = [
          'ALL_FILES_VIEW',
          'VIEW_INFO',
          'VIEW_REQUESTS',
          'EDIT_PERMISSIONS',
          'SHARE_FILES',
          'MAKE_REQUEST',
          'UPLOAD_FILES',
          'EDIT_INFO'
        ]
      } else if (this.selectedPermissionLevel === 'Chart Sharer') {
        permissionsToBeAssigned = [
          'ALL_FILES_VIEW',
          'VIEW_INFO',
          'SHARE_FILES',
          'EDIT_PERMISSIONS'
        ]
      } else if (this.selectedPermissionLevel === 'Chart Viewer') {
        permissionsToBeAssigned = [
          'ALL_FILES_VIEW',
          'VIEW_INFO'
        ]
      } else if (this.selectedPermissionLevel === 'Remove All Permissions') {
        permissionsToBeAssigned = []
      }
      const id = this.patient._id
      let payload = [{}]
      let recipientForAction = ''
      let typeForAction = ''
      if (this.options.userToEdit.user) {
        payload[0].user = this.options.userToEdit.user.id
        payload[0].allow = permissionsToBeAssigned
        typeForAction = 'individual'
        recipientForAction = this.options.userToEdit.user.id
      } else if (this.options.userToEdit.group) {
        payload[0].userGroup = this.options.userToEdit.group.id
        payload[0].allow = permissionsToBeAssigned
        typeForAction = 'group'
        recipientForAction = this.options.userToEdit.group.id
      } else {
        this.toast('error', 'Please try again later')
      }
      this.updateIndividualPermissions({
        id: this.patient._id,
        payload: payload
      }).then(() => {
        this.userActivity({patient: this.patient._id, type: 'edit_permissions', payload: {permissions: permissionsToBeAssigned, permissionLevel: this.selectedPermissionLevel, recipient: recipientForAction, type: typeForAction, displayName: this.options.userToEdit.displayName}})
        this.toast('success', 'Permissions Updated')
        this.options.userToEdit.permissionLevel = this.selectedPermissionLevel
        this.getPreliminaryPermissions({ id })
        this.close()
      })
    },
    close() {
      this.cancel()
    },
    onOpen() {
      this.selectedPermissionLevel = this.options.userToEdit.permissionLevel
    }
  }
}
</script>