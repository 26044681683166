<template>
  <v-layout justify-start>
    <edit-permissions-dialog @close="refreshPermissions()" ref="editPermissions" />
    <v-flex xs12>
      <v-card flat class="flex" color="grey lighten-4">
        <v-card flat color="transparent" class="pa-4">
          <v-row align="center" justify="space-between" class="mb-2" no-gutters>
            <span class="font-weight-bold text-h6">
              Chart Access
            </span>
            <!-- <v-btn color="primary" text :disabled="(patient && !patient.userCanUpdate)" depressed @click="addThreshold"><v-icon left>mdi-file-document-box-plus-outline</v-icon> add Rule</v-btn> -->
          </v-row>
          <v-row class="" no-gutters>
            <span class="font-weight-light text-body-1">
              This is a list of the users and organizations that have access to this chart.
            </span>
          </v-row>
        </v-card>
        <v-divider />
        <v-data-table
          style="max-width: 100%; background-color: transparent"
          :items="chartAccessList"
          :items-per-page="500"
          item-key="displayName"
          class="elevation-0"
          hide-default-footer
          :headers="headers"
          :loading="loadingFollowers"
          hide-default-header
        >
          <template v-slot:body="{ items }">
            <tbody>
              <tr v-for="(item, index) in items" :key="index" style="background-color: transparent">
                <td>
                  {{ item.displayName }} <span style="opacity:0.6">{{ item.email }}</span>
                </td>
                <td style="width:60px">
                  <v-layout row justify-center
                    ><v-btn @click="removeCollaborator(item)" icon color="red"
                      ><v-icon>mdi-close</v-icon></v-btn
                    ></v-layout
                  >
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
        <v-layout row align-center style="height: 300px;" v-if="chartAccessList.length === 0">
          <v-layout row justify-center>
            <v-icon style="opacity:0.3" size="80">mdi-account</v-icon>
          </v-layout>
        </v-layout>
      </v-card>
      <v-card v-if="inviteList && inviteList.length > 0" outlined tile flat>
        <v-toolbar flat>
          <v-toolbar-title>
            Pending Invitations
          </v-toolbar-title>
        </v-toolbar>
        <v-divider></v-divider>
        <v-data-table
          style="max-width: 100%"
          :items="inviteList"
          item-key="displayName"
          class="elevation-0"
          :headers="inviteHeaders"
          :hide-default-header="isMobile"
          hide-default-footer
          :loading="updatingInvited"
        >
          <template v-slot:body="{ items }">
            <tbody>
              <tr v-for="(item, index) in items" :key="index" :class="alternatingColors(index)">
                <td>
                  {{ item.email }}
                </td>
                <td>
                  {{ item.expires }}
                </td>
                <td style="max-width: 80px; width: 80px;">
                  <v-layout row justify-center>
                    <v-btn :disabled="!patient.userCanUpdate" color="red" @click="deleteInviteHere(item)" icon
                      ><v-icon>mdi-close</v-icon></v-btn
                    >
                  </v-layout>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import HelperMixin from '@/core/mixins/HelperMixin'
import EditPermissionsDialog from '@/components/dialogs/EditPermissionsDialog'
import bus from '@/core/helpers/bus'

export default {
  mixins: [HelperMixin],
  data() {
    return {
      headers: [
        {
          text: 'Individual or Group',
          align: 'start',
          sortable: true,
          value: 'displayName',
        },
        {
          text: 'Remove',
          sortable: true,
          align: 'right',
          value: 'permissionLevel',
        },
      ],
      permissionLevels: ['Admin', 'Viewer'],
      permissionList: [
        {
          title: 'View Chart',
          text: 'User can view the entire chart as well as view information added to the chart in the future',
        },
        {
          title: 'Edit Patient Info',
          text: 'User can modify patient information in the chart.',
        },
        { title: 'Share Chart', text: 'User can share the chart with others.' },
        {
          title: 'Edit Chart Permissions',
          text: "User can modify other user's permissions on the chart",
        },
        {
          title: 'Create Records Requests',
          text: 'User can request records from providers to be added to the chart.',
        },
      ],
      chartAccessList: [],
      options: {},
      rawCollaborators: null,
      inviteList: [],
      updatingInvited: false,
    }
  },
  components: {
    EditPermissionsDialog,
  },
  computed: {
    ...mapState('patient', [
      'patients',
      'patient',
      'files',
      'permissions',
      'individualPermissions',
      'loadingFollowers',
      'defaultLogsPerPage',
      'patientLogs',
      'patientLogsPageInfo',
      'refreshingLogs',
    ]),
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown || false
    },
    inviteHeaders() {
      return [
        {
          text: 'Recipient',
          align: 'start',
          sortable: false,
          value: 'email',
        },
        {
          text: 'Expiration',
          align: 'start',
          sortable: false,
          value: 'expires',
        },
        {
          text: 'Delete',
          align: 'center',
          sortable: false,
        },
      ]
    },
  },
  watch: {
    options(val) {
      if (this.patientLogsPageInfo) {
        this.getPatientLogs({
          id: this.$route.params.id,
          requestedLogsInfo: val,
        })
      }
    },
  },
  methods: {
    ...mapActions('patient', [
      'getPatient',
      'getPermissions',
      'getPatientLogs',
      'getChartActivity',
      'updatePatientCollaborators',
      'getPatientInvites',
      'deleteInvite',
    ]),
    ...mapActions('provider', ['getProviderAuth']),
    shareChart() {
      this.$emit('shareChart', this.patient)
    },
    openEditPermissionsDialog(user) {
      this.$refs.editPermissions.open({
        userToEdit: user,
      })
    },
    deleteInviteHere(invite) {
      this.deleteInvite(invite._id)
        .then(() => {
          bus.$emit('refreshPatientInviteList')
          this.toast('success', 'Invite Removed')
        })
        .catch(error => {
          console.log(error)
          this.toast('error', 'Please Try Again Later')
        })
    },
    getMatchingAdminObjectIndex(o) {
      let admins = this.patient.admins
      for (var i = 0; i < admins.length; i++) {
        if (admins[i].id == o.id) {
          return i
        }
      }
      return -1
    },
    updateCollaborator(item) {
      // patch the patient
      // remove it from both the admin and the viewer
      let admins = this.patient.admins
      let viewers = this.patient.viewers

      if (item.level === '0') {
        admins.push({ id: item.id, ref: item.type })
      } else {
        let index = this.getMatchingAdminObjectIndex({ id: item.id, ref: item.type })
        if (index > -1) {
          admins.splice(index, 1)
        }
      }
      // update the patient
      this.updatePatientCollaborators({
        patientID: this.$route.params.id,
        admins: admins,
        viewers: viewers,
      })
        .then(() => {
          this.toast('success', 'Permissions Updated')
        })
        .catch(error => {
          console.log(error)
          this.toast('error', 'Please try again later')
        })
    },
    removeCollaborator(item) {
      // patch the patient
      // remove it from both the admin and the viewer
      let admins = this.patient.admins
      let viewers = this.patient.viewers

      this.patient.admins.forEach(admin => {
        if (admin.id === item.id) {
          let index = admins.indexOf(admin)
          if (index > -1) {
            admins.splice(index, 1)
          }
        }
      })
      this.patient.viewers.forEach(viewer => {
        if (viewer.id === item.id) {
          let index = viewers.indexOf(viewer)
          if (index > -1) {
            viewers.splice(index, 1)
          }
        }
      })
      // update the patient
      this.updatePatientCollaborators({
        patientID: this.$route.params.id,
        admins: this.patient.admins,
        viewers: this.patient.viewers,
      })
        .then(() => {
          this.toast('success', 'Permissions Updated')
          this.getList()
        })
        .catch(error => {
          console.log(error)
          this.toast('error', 'Please try again later')
        })
    },
    toast(type, text) {
      bus.$emit('toast', { type, text })
    },
    getInvites() {
      this.updatingInvited = true
      this.getPatientInvites({ id: this.$route.params.id }).then(data => {
        this.updatingInvited = false
        if (data.items) {
          this.inviteList = data.items
        }
      })
    },
  },
  mounted() {},
}
</script>

<style></style>
